import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ListoFReport from "./ListOfReports";
import config from "../../config/config.json";
import { apiCall } from "../../services/ApiCall";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [status, setStatus] = useState("");
  const [reportType, setReportType] = useState("");
  const [caseList, setCaseList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const onFileChange = async (event) => {
    try {
      const timestamp = new Date().getTime();
      const fileName = `${timestamp}_${event.target.files[0]?.name}`;
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("fileName", fileName);
      formData.append("filePath", "msi/");

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const response = await fetch(
        "https://backend-cac.veriproved.com/v1.0/auth/uploadFile",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const result = await response.json();
      const url = result.url;
      setFileName(fileName);
      setFileUrl(url);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const uploadCaseStatus = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}uploadCaseStatus`,
      {},
      {
        fileUrl: fileUrl,
        fileName: fileName,
        status: status,
        reportType: reportType,
      },
      "POST"
    );

    if (isSuccess) {
      setFileUrl("");
      setFileName("");
      setStatus("");
      setReportType("");
      getCaseStatus();

      Swal.fire("Uploaded!", "Your case status has been uploaded.", "success");
    } else {
      Swal.fire(
        "Error!",
        "Failed to upload case status. Please try again later.",
        "error"
      );
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}serchGetCaseStatus`,
      {},
      {
        fromDate: fromDate,
        toDate: toDate,
        // fileName: searchQuery,
      },
      "GET"
    );

    if (isSuccess) {
      const filteredResults = data?.data && data?.data.length ? data?.data.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
        )
      ) : [];
      setCaseList(filteredResults.reverse());
      setFromDate("");
      setToDate("");
      setSearchQuery("");
    }
  };

  const getCaseStatus = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCaseStatus`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setCaseList(data?.data?.reverse());
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getCaseStatus();
  }, []);
  return (
    <>
      <Header />
      <Container size="lg">
        <section>
          <Form onSubmit={handleFormSubmit}>
            <Row className="Filter_View align-items-end">
              <Form.Group as={Col} md="3">
                <Form.Label>
                  <b>From Date</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="3">
                <Form.Label>
                  <b>To Date</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" className="search_result">
                <Form.Control
                  type="search"
                  placeholder="File/Report Name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Button type="submit">
                  <i className="fa-solid fa-magnifying-glass"></i> Search
                </Button>
              </Form.Group>
            </Row>
          </Form>
        </section>
        <section>
          <Row className="Filter_View align-items-end">
            <Form.Group as={Col} md="4">
              <Form.Label>
                <b>Upload</b>
              </Form.Label>
              <Form.Control
                type="file"
                defaultValue={fileName}
                onChange={(e) => onFileChange(e)}
              />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>
                <b>Status</b>
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                defaultValue={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option>-Select one-</option>
                <option value="Fail">Fail</option>
                <option value="Pass">Pass</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>
                <b>Report Type</b>
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                defaultValue={reportType}
                onChange={(e) => setReportType(e.target.value)}
              >
                <option>-Select one-</option>
                <option value="Criminal">Criminal</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="2">
              <Button
                type="submit"
                className="w-100"
                onClick={uploadCaseStatus}
              >
                <i className="fa-solid fa-floppy-disk"></i> Save
              </Button>
            </Form.Group>
          </Row>
        </section>
        <ListoFReport caseList={caseList} getCaseStatus={getCaseStatus} />
      </Container>
    </>
  );
}
