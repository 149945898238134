import React, { useState } from "react";
import config from "../../config/config.json";
import { apiCall } from "../../services/ApiCall";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import Swal from "sweetalert2";

export default function ListoFReport({ caseList, getCaseStatus }) {
  const [currentUrl, setCurrentUrl] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (fileUrl) => {
    let updatedUrl = fileUrl;
 
    if (fileUrl.includes('ap-south-1')) {
      updatedUrl = updatedUrl.replace('ap-south-1', 'us-east-1');
    }
  
    if (fileUrl.includes('msi-cac') && !fileUrl.includes('msi-cac1')) {
      updatedUrl = updatedUrl.replace('msi-cac', 'msi-cac1');
    }
  
    window.open(updatedUrl, '_blank');
  };


  const deleteCaseStatus = async (id) => {
    const { value: confirmation } = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this case status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmation) {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}deleteCaseStatus`,
        {},
        { id: id },
        "POST"
      );

      if (isSuccess) {
        getCaseStatus();
        Swal.fire("Deleted!", "Your case status has been deleted.", "success");
      }
    }
  };
  return (
    <>
      {/* Table Result ------------------------------------- */}
      <section>
        <Row className="Filter_View align-items-end mb-4">
          <Form.Group as={Col} md="12">
            <Form.Label>
              <b>List Of Reports</b>
            </Form.Label>
            <div className="table_height">
              <Table>
                <thead className="thead_sticky">
                  <tr>
                    <th>File Name</th>
                    <th>Status</th>
                    <th>Report Type</th>
                    <th>Completed Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {caseList && caseList?.length > 0
                  ? caseList.map((item) => {
                      return (
                        <tbody>
                          <tr>
                            <td>{item?.fileName}</td>
                            <td>{item?.status}</td>
                            <td>{item?.reportType}</td>
                            <td>
                              {new Date(item?.createdAt).toLocaleString()}
                            </td>
                            <td>
                              <div className="hstack gap-3">
                                <Button
                                  onClick={() => handleShow(item?.fileUrl)}
                                >
                                  <i className="fa-solid fa-eye"></i>
                                </Button>
                                <Button
                                  onClick={() => deleteCaseStatus(item?.id)}
                                >
                                  <i class="fa-solid fa-trash-can"></i>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })
                  : "No data"}
              </Table>
            </div>
          </Form.Group>
        </Row>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={currentUrl} width="100%" height="500px"></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
